<template>
  <van-tabbar v-model="active" @change="handleChange">
    <van-tabbar-item name="home" icon="home-o">首页</van-tabbar-item>
    <van-tabbar-item name="household" icon="friends-o">住户</van-tabbar-item>
    <van-tabbar-item name="warn" icon="warning-o">预警</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";

export default {
  setup() {
    const active = ref("home");

    const router = useRouter();
    const route = useRoute();

    const handleChange = (val) => {
      router.push(val);
    };

    onMounted(() => {
      active.value = route.name;
    });

    onBeforeRouteUpdate((to) => {
      active.value = to.name;
    });

    return { active, handleChange };
  },
};
</script>

<style scoped></style>
