<template>
  <div class="container">
    <NavBar />

    <div class='main'>
      <van-loading size="24px" v-show='loading'>加载中...</van-loading>
      
      <router-view v-show='!loading' @changeLoading='changeLoading'/>
    </div>
   

    <TabBar />
  </div>
</template>

<script>
import NavBar from "../components/Header";
import TabBar from "../components/Footer";
import { useRouter } from "vue-router";
import { Toast } from "vant";
import {ref} from 'vue';

export default {
  components: {
    NavBar,
    TabBar,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);

    router.beforeEach(() => {
      if (!localStorage.getItem("user_info")) {
        Toast("暂未登录！");
        router.push("/login");
      }
    });

    const changeLoading = (bool) => {
      loading.value = bool;
    }

    return {
      loading,
      changeLoading
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .main {
    overflow: scroll;
    scrollbar-width: none;
    padding: 0 0.1rem 0.5rem;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .van-loading {
      transform: translateY(50%);
      margin-top: 50%;
    }
  }
}
</style>
