<template>
  <van-nav-bar :title="route.meta.title" left-arrow @click-left="onClickLeft" />
</template>

<script>
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const onClickLeft = () => {
      if (route.path != "/home") {
        router.go(-1);
      }
    };

    return { route, onClickLeft };
  },
};
</script>
